import React, { useState, useEffect } from "react";
import Checkbox from "components/Checkbox";
import style from "./style.module.scss";
import CheckboxSearchWrapper from "../CheckboxSearchWrapper";
import PropTypes from "prop-types";
import SelectAllCurrentAndFuture from "../SelectAllCurrentAndFuture";
import classNames from "classnames";
import LazilyRender from "react-lazily-render";

/**
 * Produces a search component with local checkbox state
 * @param {object} props
 * @param {string} props.className classname of component
 * @param {Array<Object>} props.options Collection of checkboxes objects to iterate and search over
 * @param {object} props.search the accessor and the placeholder
 * @param {object} props.search.accessor search component key accessor for options
 * @param {object} props.search.placeholder search component placeholder
 * @param {func} props.onChangeAll when the change all button is clicked
 * @param {bool} props.selectAllByDefault lets the user change all by default?
 * @param {bool} props.disableSelectAll disable the select all
 * @param {string} props.selectAllTxt select all text
 * @param {func} props.bottomBar bottom bar of component
 * @param {bool} props.largeSelect is a large select box
 * @param {bool} props.isLoading is loading
 * @param {string} props.itemType itemType???
 * @param {func} props.onChange function on change
 * @param {bool} props.reverse is the checkbox options reversed
 *
 * @returns {JSX.Element}
 */

const CheckboxSearchWithComponent = ({
    options = [],
    className,
    search = {},
    onChangeAll,
    selectAllByDefault,
    disableSelectAll = false,
    selectAllTxt,
    bottomBar: BottomBar,
    sortFn,
    largeSelect = false,
    isLoading = false,
    itemType = "",
    onChange,
    reverse = false,
    hideSelectAll = false,
    filters,
    searchTermInText = false,
    selectAllCurrentAndFuture,
    selectAllCurrentAndFutureDefault,
    selectAllCurrentAndFutureForceValue,
    extraContent,
    lazyLoad = false,
    clearTxt,
    onClearAll,
    toggleFocus,
}) => {
    const [currentAndFuture, toggleCurrentAndFuture] = useState(selectAllCurrentAndFutureDefault);
    const [searchTerm, changeSearchTerm] = useState("");

    useEffect(() => {
        toggleCurrentAndFuture(selectAllCurrentAndFutureDefault);
    }, [selectAllCurrentAndFutureDefault]);

    useEffect(() => {
        if (
            typeof selectAllCurrentAndFutureForceValue !== "undefined" &&
            selectAllCurrentAndFutureForceValue !== null
        ) {
            toggleCurrentAndFuture(selectAllCurrentAndFutureForceValue);
        }
    }, [selectAllCurrentAndFutureForceValue]);

    return (
        <CheckboxSearchWrapper
            filters={filters}
            options={options}
            className={className}
            sortFn={sortFn}
            search={search}
            onChangeAll={onChangeAll}
            selectAllByDefault={selectAllByDefault}
            disableSelectAll={disableSelectAll}
            selectAllTxt={selectAllTxt}
            bottomBar={BottomBar}
            largeSelect={largeSelect}
            reverse={reverse}
            hideSelectAll={hideSelectAll}
            searchTermInText={searchTermInText}
            selectAllCurrentAndFuture={selectAllCurrentAndFuture}
            currentAndFuture={currentAndFuture}
            searchTerm={searchTerm}
            changeSearchTerm={changeSearchTerm}
            extraContent={extraContent}
            clearTxt={clearTxt}
            onClearAll={onClearAll}
            toggleFocus={toggleFocus}
            isLoadingData={isLoading}
        >
            {({ foundList }) => (
                <CheckboxList
                    foundList={foundList}
                    reverse={reverse}
                    onChange={onChange}
                    isLoading={isLoading}
                    itemType={itemType}
                    selectAllCurrentAndFuture={selectAllCurrentAndFuture}
                    toggleCurrentAndFuture={toggleCurrentAndFuture}
                    currentAndFuture={currentAndFuture}
                    searchTerm={searchTerm}
                    extraContent={extraContent}
                    lazyLoad={lazyLoad}
                />
            )}
        </CheckboxSearchWrapper>
    );
};

CheckboxSearchWithComponent.propTypes = {
    options: PropTypes.array,
    className: PropTypes.string,
    search: PropTypes.object,
    onChangeAll: PropTypes.func,
    selectAllByDefault: PropTypes.bool,
    disableSelectAll: PropTypes.bool,
    selectAllTxt: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    bottomBar: PropTypes.func,
    largeSelect: PropTypes.bool,
    isLoading: PropTypes.bool,
    itemType: PropTypes.string,
    onChange: PropTypes.func,
    sortFn: PropTypes.func,
    reverse: PropTypes.bool,
    selectAllCurrentAndFuture: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

export default CheckboxSearchWithComponent;

function updateCheckedListItem(label, checked) {
    return (array, item) => [...array, item.label === label ? { ...item, checked } : item];
}

/**
 * Checkbox with local state
 * @param {object} props
 * @param {boolean} props.isLoading
 * @param {array} props.foundList found list of items
 * @param {string} props.itemType loading text for loading
 * @param {func} props.onChange function when changed
 * @returns {JSX.Element}
 */

function CheckBoxPlaceHolder() {
    return (
        <div
            style={{
                height: "3rem",
                width: "100%",
                borderRadius: "0.5rem",
                backgroundColor: "#f8f9fb",
                display: "block",
                marginBottom: "0.25rem",
            }}
        ></div>
    );
}

export function CheckboxList({
    isLoading,
    foundList,
    itemType,
    onChange,
    currentAndFuture,
    toggleCurrentAndFuture,
    selectAllCurrentAndFuture,
    searchTerm,
    extraContent,
    lazyLoad,
}) {
    const [checkedList, changeList] = useState(foundList);

    useEffect(() => changeList(foundList), [foundList]);

    return (
        <>
            {isLoading && (
                <div className={style.loading}>{itemType ? <p>Loading {itemType}...</p> : <p>Loading...</p>}</div>
            )}
            {!isLoading && !searchTerm && typeof selectAllCurrentAndFuture === "function" && (
                <SelectAllCurrentAndFuture
                    active={currentAndFuture}
                    onChange={() => {
                        toggleCurrentAndFuture(!currentAndFuture);
                        selectAllCurrentAndFuture(!currentAndFuture);
                    }}
                    itemType={itemType}
                />
            )}

            {extraContent && extraContent}

            {!isLoading &&
                checkedList.map((listitem, index) => {
                    const {
                        label,
                        labelDetails,
                        subLabel,
                        tip,
                        id,
                        checked = null,
                        disabled = false,
                        autoChecked = false,
                        indent = false,
                        component,
                        className: checkboxClassName,
                        tipPosition = "top",
                        categoryHeading = "",
                    } = listitem;

                    const input = {
                        onChange: ({ target: { checked } }) => {
                            changeList(checkedList.reduce(updateCheckedListItem(listitem.label, checked), []));
                            onChange({ ...listitem, checked });
                        },
                        checked,
                    };

                    const checkboxClasses = classNames(style.checkbox, {
                        [style.disabled]: disabled,
                        [style.autoChecked]: autoChecked || currentAndFuture,
                        [checkboxClassName]: checkboxClassName,
                    });

                    const RenderCheckbox = (
                        <Checkbox
                            className={checkboxClasses}
                            labelClassName={classNames(style.checkboxSearchLabel, {
                                [style.checkedLabel]: checked,
                                [style.hasLabelDetails]: !!labelDetails,
                            })}
                            subLabel={subLabel}
                            labelDetails={labelDetails}
                            label={component ? <CheckboxComponent label={label} component={component} /> : label}
                            input={input}
                            id={id}
                            disabled={disabled || currentAndFuture}
                            autoChecked={autoChecked || currentAndFuture}
                            indent={indent}
                            tip={tip}
                            tipPosition={tipPosition}
                        />
                    );

                    return (
                        <React.Fragment key={id ? id : label}>
                            {categoryHeading && <p className={style.catHeading}>{categoryHeading}</p>}
                            {lazyLoad ? (
                                <LazilyRender
                                    key={`${id}-${index}`}
                                    className={style.lazyWrapper}
                                    content={RenderCheckbox}
                                    //offset={200}
                                    placeholder={<CheckBoxPlaceHolder />}
                                />
                            ) : (
                                RenderCheckbox
                            )}
                        </React.Fragment>
                    );
                })}
        </>
    );
}

function CheckboxComponent({ label, component: Component }) {
    return (
        <span className={style.checkboxLabel}>
            <span>{label}</span>
            {Component && Component}
        </span>
    );
}

CheckboxList.propTypes = {
    isLoading: PropTypes.bool,
    foundList: PropTypes.array,
    itemType: PropTypes.string,
    onChange: PropTypes.func,
};
