import createReducer from "../../lib/createReducer";
import {
    register,
    getSubscription,
    resetPasswordData,
    updateUserData,
    storeBillingDetails,
    fetchBillingDetails,
    storeSubscriptionDetails,
    removeUser,
    fetchInvoices,
    fetchTicket,
    updatePassword,
    changeUserPasswordRequest,
    updateUserAvatarRequest,
    deleteUserAvatarRequest,
    reloadUserUsageData,
    updateBillingEmailData,
    updateCompanyLogoRequest,
    deleteCompanyLogoRequest,
    updateCompanyColor,
    createApiTokenData,
    updateAppSumoCode,
    revokeTeamAccessRequest,
} from "./async";
import { authenticate, fetchAndSetProfile, googleAuthenticate } from "../../lib/auth";
import { handle } from "redux-pack";
import { getFormValues } from "redux-form";
import merge from "lodash/merge";
import {
    selectUser,
    selectSeenIt,
    makeSelectUserId,
    selectSetupGuideDismissed,
    selectSetupGuideToggle,
    makeSelectUserMetaData,
    selectControlPanelSettings,
    selectUserMetaOverviewUi,
    makeSelectAnomalyFilter,
} from "./selectors";
import { MorphioAPI } from "../../lib/api";
import { updateUi } from "features/ControlPanel/ducks/actions";
import { isEmpty } from "lodash";
import { channelFriendlyName } from "lib/utility";

/**
 *  ACTION TYPES
 */

const SET_BILLING_DETAILS = "SET_BILLING_DETAILS";

const GET_BILLING_DETAILS = "GET_BILLING_DETAILS";

const REGISTER_USER = "REGISTER_USER";

const POPULATE_USER = "POPULATE_USER";

const TEST_INTEGRATIONS_PENDING = "TEST_INTEGRATIONS_PENDING";

const SIGNIN_USER = "SIGNIN_USER";

const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";

const CREATE_API_TOKEN = "CREATE_API_TOKEN";

/**
 *  ACTIONS
 */

export const createApiToken = ({ userId }) => (dispatch) =>
    dispatch({
        type: CREATE_API_TOKEN,
        promise: createApiTokenData({ userId }),
    });

export const cancelSubscription = ({ userId }) => (dispatch) =>
    dispatch({
        type: CANCEL_SUBSCRIPTION,
        promise: removeUser({ userId }),
    });

export const setSubscriptionDetails = ({ plan, promo } = {}) => (dispatch) =>
    dispatch({
        type: "SET_SUBSCRIPTION_DETAILS",
        promise: storeSubscriptionDetails({
            plan,
            promo,
        }),
    });

export const setBillingDetails = ({ token, userId } = {}) => (dispatch) => {
    return dispatch({
        type: SET_BILLING_DETAILS,
        promise: storeBillingDetails({
            token,
            userId,
        }),
    });
};

const RELOAD_USER_USAGE = "RELOAD_USER_USAGE";
export const reloadUserUsage = () => ({
    type: RELOAD_USER_USAGE,
    promise: reloadUserUsageData(),
});

export const getBillingDetails = (userId) => (dispatch) =>
    dispatch({
        type: GET_BILLING_DETAILS,
        promise: fetchBillingDetails(userId),
    });

const GET_INVOICES = "GET_INVOICES";
export const getInvoices = () => (dispatch) =>
    dispatch({
        type: GET_INVOICES,
        promise: fetchInvoices({}),
    });

const GET_TICKET = "GET_TICKET";
export const getTicket = ({ ticketId } = {}) => (dispatch) =>
    dispatch({
        type: GET_TICKET,
        promise: fetchTicket({
            ticketId,
        }),
    });

export const registerUser = ({ email, company, name, password, plan, hasTrial } = {}) => (dispatch, getState) =>
    dispatch({
        type: REGISTER_USER,
        promise: register({
            email,
            company,
            name,
            password,
            plan,
            hasTrial,
        }),
    });

const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const setNewPassword = ({ userId, ticketId }) => (dispatch, getState) => {
    const state = getState();
    const { password } = getFormValues("setNewPassword")(state);

    return dispatch({
        type: UPDATE_PASSWORD,
        promise: updatePassword({
            ticketId,
            password,
        }),
    });
};

const UPDATE_USER_NICKNAME = "UPDATE_USER_NICKNAME";
export const updateUserNickname = ({ name, userId }) => (dispatch, getState) => {
    return dispatch({
        type: UPDATE_USER_NICKNAME,
        name,
        promise: updateUserData({
            userId,
            data: {
                user_metadata: {
                    nickname: name,
                },
            },
        }).then(() => {
            return fetchAndSetProfile();
        }),
    });
};

const UPDATE_USER_PHONE = "UPDATE_USER_PHONE";
export const updateUserPhone = ({ phone, userId }) => ({
    type: UPDATE_USER_PHONE,
    promise: updateUserData({
        userId,
        data: {
            user_metadata: {
                phone,
            },
        },
    }),
});

const UPDATE_USER_DOWNGRADE = "UPDATE_USER_DOWNGRADE";
export const updateUserDowngrade = ({ datetime, userId }) => ({
    type: UPDATE_USER_DOWNGRADE,
    promise: updateUserData({
        userId,
        data: {
            user_metadata: {
                freemiumDowngrade: datetime,
            },
        },
    }),
});

const UPDATE_USER_TRIAL_DISMISS = "UPDATE_USER_TRIAL_DISMISS";
export const updateUserTrialDismiss = ({ datetime, userId }) => ({
    type: UPDATE_USER_TRIAL_DISMISS,
    promise: updateUserData({
        userId,
        data: {
            user_metadata: {
                trialWallDismiss: datetime,
            },
        },
    }),
});

const UPDATE_APP_METADATA = "UPDATE_APP_METADATA";
export const updateAppMetaData = ({ data, userId }) => ({
    type: UPDATE_APP_METADATA,
    promise: updateUserData({
        userId,
        data: {
            app_metadata: data,
        },
    }),
});

const UPDATE_USER_METADATA = "UPDATE_USER_METADATA";
export const updateUserMetaData = ({ data, userId, replace = false }) => ({
    type: UPDATE_USER_METADATA,
    promise: updateUserData({
        userId,
        data: {
            user_metadata: data,
        },
        replace,
    }),
});

const UPDATE_DASHBOARD_FILTERS = "UPDATE_DASHBOARD_FILTERS";
export const updateDashboardFilters = ({ data, userId, accountId, abortController }) => ({
    type: UPDATE_DASHBOARD_FILTERS,
    meta: { data, accountId },
    promise: MorphioAPI({
        url: `user/dashboardFilters`,
        method: "put",
        data: {
            data,
            userId,
            accountId,
        },
        abortController,
    }).then(({ data }) => data),
});

const UPDATE_USER_CONTROL_PANEL_SETTINGS = "UPDATE_USER_CONTROL_PANEL_SETTINGS";
export const updateUserControlPanelSettings = ({ settings, userId, replace = false }) => (dispatch, getState) => {
    const state = getState();
    const userMetadata = makeSelectUserMetaData()(state);
    const controlPanelSettings = selectControlPanelSettings(state);

    return dispatch({
        type: UPDATE_USER_CONTROL_PANEL_SETTINGS,
        meta: { settings },
        promise: updateUserData({
            userId,
            data: {
                user_metadata: {
                    ...userMetadata,
                    controlPanelSettings: {
                        ...controlPanelSettings,
                        ...settings,
                    },
                },
            },
            replace,
        }),
    });
};

const UPDATE_CONTROL_PANEL_OVERVIEW_UI = "UPDATE_CONTROL_PANEL_OVERVIEW_UI";
export const updateOverviewUi = ({ ui, userId, replace = false }) => (dispatch, getState) => {
    const state = getState();
    const overviewUi = selectUserMetaOverviewUi(state);

    dispatch(updateUi({ overviewUi: ui }));
    return dispatch(updateUserControlPanelSettings({ settings: { ui: { ...overviewUi, ...ui } }, userId, replace }));
};

/**
 * UPDATE SEEN IT
 */

const UPDATE_SEEN_IT = "UPDATE_SEEN_IT";
export const updateSeenIt = ({ it, seen, updated }) => (dispatch, getState) => {
    const state = getState();
    const seenIt = selectSeenIt(state);
    const userId = makeSelectUserId()(state);

    // Update state first
    dispatch({
        type: UPDATE_SEEN_IT,
        it,
        seen,
        updated,
    });

    return updateUserData({
        userId,
        data: {
            user_metadata: {
                seen_it: {
                    ...seenIt,
                    [it]: {
                        seen,
                        updated,
                    },
                },
            },
        },
    });
};

const UPDATE_SETUP_GUIDE_TOGGLE = "UPDATE_SETUP_GUIDE_TOGGLE";
export const updateSetupGuideToggle = ({ accountId, isOpen }) => (dispatch, getState) => {
    const state = getState();
    const seenIt = selectSetupGuideToggle(state);
    const userId = makeSelectUserId()(state);

    // Update state first
    dispatch({
        type: UPDATE_SETUP_GUIDE_TOGGLE,
        accountId,
        isOpen,
    });

    return updateUserData({
        userId,
        data: {
            user_metadata: {
                setup_guide_toggle: {
                    ...seenIt,
                    [accountId]: isOpen,
                },
            },
        },
    });
};

const UPDATE_SELECTED_WORKSPACE = "UPDATE_SELECTED_WORKSPACE";
export const updateSelectedWorkspace = ({ workspaceId }) => (dispatch, getState) => {
    dispatch({
        type: UPDATE_SELECTED_WORKSPACE,
        workspaceId,
    });
};

const UPDATE_HAS_BOOKED_MEETING = "UPDATE_HAS_BOOKED_MEETING";
export const hasBookedMeeting = () => (dispatch, getState) => {
    const state = getState();
    const userId = makeSelectUserId()(state);

    // Update state first
    dispatch({
        type: UPDATE_HAS_BOOKED_MEETING,
        hasBooked: true,
    });

    return updateUserData({
        userId,
        data: {
            user_metadata: {
                has_booked_meeting: true,
            },
        },
    });
};

/**
 * UPDATE Setup Guide Dismissed
 */

const UPDATE_SETUP_GUIDE_DISMISSED = "UPDATE_SETUP_GUIDE_DISMISSED";
export const updateSetupGuideDismissed = ({ it, dismissed, updated }) => (dispatch, getState) => {
    const state = getState();
    const setup_guide_dismissed = selectSetupGuideDismissed(state);
    const userId = makeSelectUserId()(state);

    // Update state first
    dispatch({
        type: UPDATE_SETUP_GUIDE_DISMISSED,
        it,
        dismissed,
        updated,
    });

    return updateUserData({
        userId,
        data: {
            user_metadata: {
                setup_guide_dismissed: {
                    ...setup_guide_dismissed,
                    [it]: {
                        dismissed,
                        updated,
                    },
                },
            },
        },
    });
};

const UPDATE_USER_AVATAR = "UPDATE_USER_AVATAR";
export const updateUserAvatar = ({ file, fileType, userId }) => (dispatch, getState) => {
    return dispatch({
        type: UPDATE_USER_AVATAR,
        promise: updateUserAvatarRequest({
            userId,
            fileFormat: fileType,
            file,
        }).then((data) => {
            return fetchAndSetProfile();
        }),
    });
};

const CLEAR_USER_AVATAR = "CLEAR_USER_AVATAR";
export const clearUserAvatar = ({ file, fileType, userId }) => (dispatch, getState) => {
    return dispatch({
        type: CLEAR_USER_AVATAR,
        promise: deleteUserAvatarRequest({
            userId,
            fileFormat: fileType,
            file,
        }).then((data) => {
            return fetchAndSetProfile();
        }),
    });
};

const UPDATE_COMPANY_COLOR = "UPDATE_COMPANY_COLOR";
export const updateColor = ({ color, userId }) => (dispatch, getState) => {
    return dispatch({
        type: UPDATE_COMPANY_COLOR,
        promise: updateCompanyColor({
            userId,
            color,
        }).then((data) => {
            return fetchAndSetProfile();
        }),
    });
};

const UPDATE_COMPANY_LOGO = "UPDATE_COMPANY_LOGO";
export const updateCompanyLogo = ({ file, fileType, userId }) => (dispatch, getState) => {
    return dispatch({
        type: UPDATE_COMPANY_LOGO,
        promise: updateCompanyLogoRequest({
            userId,
            fileFormat: fileType,
            file,
        }).then((data) => {
            return fetchAndSetProfile();
        }),
    });
};

const CLEAR_COMPANY_LOGO = "CLEAR_COMPANY_LOGO";
export const clearCompanyLogo = ({ file, fileType, userId }) => (dispatch, getState) => {
    return dispatch({
        type: CLEAR_COMPANY_LOGO,
        promise: deleteCompanyLogoRequest({
            userId,
            fileFormat: fileType,
            file,
        }).then((data) => {
            return fetchAndSetProfile();
        }),
    });
};

const CHANGE_USER_PASSWORD = "CHANGE_USER_PASSWORD";
export const changeUserPassword = ({ userId, password, newPassword, email }) => (dispatch, getState) => {
    return dispatch({
        type: CHANGE_USER_PASSWORD,
        promise: changeUserPasswordRequest({
            newPassword,
            password,
            userId,
            email,
        }),
    });
};

export const resetPassword = ({ email } = {}) => (dispatch, getState) =>
    dispatch({
        type: REGISTER_USER,
        promise: resetPasswordData({
            email,
        }),
    });

/**
 * Re-populates the user object in instances that it is required to refresh state.
 * @param {object} user
 */

export const populateUser = ({ user } = {}) => ({
    type: POPULATE_USER,
    user,
});

/**
 * This action is for when the user logs in... it will start the sockets associated to initialization
 */
const INITIALIZE_USER = "INITIALIZE_USER";

export const initUser = ({ user } = {}) => ({
    type: INITIALIZE_USER,
    user,
});

export const testIntegrationsPending = ({ pending } = {}) => ({
    type: TEST_INTEGRATIONS_PENDING,
    pending,
});

const GET_SUBSCRIPTION = "GET_SUBSCRIPTION";
export const getUserSubscription = () => (dispatch, getState) =>
    dispatch({
        type: GET_SUBSCRIPTION,
        promise: getSubscription(),
    });

export const signin = ({ email, password, data } = {}) => (dispatch, getState) => {
    return dispatch({
        type: SIGNIN_USER,
        promise: authenticate({ email, password }),
    });
};

export const googleSignin = ({ code, state } = {}) => (dispatch, getState) => {
    return dispatch({
        type: SIGNIN_USER,
        promise: googleAuthenticate({ code, state }),
    });
};

const UPDATE_COMPANY = "UPDATE_COMPANY";
export const updateCompany = ({ company, userId } = {}) => ({
    type: UPDATE_COMPANY,
    promise: updateUserData({
        userId,
        data: {
            user_metadata: {
                company,
            },
        },
    }),
});

const UPDATE_TIMEZONE = "UPDATE_TIMEZONE";
export const updateTimezone = ({ userId, timezone } = {}) => ({
    type: UPDATE_TIMEZONE,
    promise: updateUserData({
        userId,
        data: {
            user_metadata: {
                timezone,
            },
        },
    }),
});

const UPDATE_BILLING_EMAIL = "UPDATE_BILLING_EMAIL";
export const updateBillingEmail = ({ email, userId } = {}) => ({
    type: UPDATE_BILLING_EMAIL,
    promise: updateBillingEmailData({
        userId,
        email,
    }),
});

const UPDATE_PROMOCODE = "UPDATE_PROMOCODE";
export const updatePromoCode = ({ bypassCreditCard, userId } = {}) => ({
    type: UPDATE_PROMOCODE,
    promise: updateUserData({
        userId,
        data: {
            bypassCreditCard,
        },
    }),
});

/**
 * APPSUMO
 */

const ADD_APPSUMO_CODE = "ADD_APPSUMO_CODE";
export const addAppSumoCode = ({ userId, appSumoCode }) => (dispatch, getState) => {
    return dispatch({
        type: ADD_APPSUMO_CODE,
        promise: updateAppSumoCode({
            userId,
            appSumoCode,
        }),
    });
};

const DOWNGRADE_APPSUMO_CODE = "DOWNGRADE_APPSUMO_CODE";
export const downgradeAppsumoUser = ({ userId }) => (dispatch, getState) => {
    return dispatch({
        type: DOWNGRADE_APPSUMO_CODE,
        promise: MorphioAPI({
            url: "appsumo/downgrade",
            method: "POST",
            data: {
                userId,
            },
        }),
    });
};

/**
 * TOGGLE NAVIGATION
 */

const TOGGLE_NAVIGATION = "TOGGLE_NAVIGATION";
export const toggleNavigation = () => (dispatch, getState) => {
    const state = getState();
    const { user: { sub: userId, user_metadata: { isNavOpen } = {} } = {} } = state.user || {};
    return dispatch({
        type: TOGGLE_NAVIGATION,
        promise: updateUserData({
            userId,
            data: {
                user_metadata: {
                    isNavOpen: !isNavOpen,
                },
            },
        }),
    });
};

/**
 *  ANOMALY SETTINGS
 */

const SET_DASHBOARD_ANOMALY_FILTERS = "SET_DASHBOARD_ANOMALY_FILTERS";
export const setAnomalyFilters = ({ accountId, anomalySettings }) => {
    const formatForDb = Object.keys(anomalySettings).reduce((cache, key) => {
        const value = isEmpty(anomalySettings[key]) ? 0 : anomalySettings[key];

        return {
            ...cache,
            [key]: value,
        };
    }, {});

    return {
        type: SET_DASHBOARD_ANOMALY_FILTERS,
        value: { [accountId]: formatForDb },
    };
};

const SAVE_DASHBOARD_ANOMALY_FILTERS = "SAVE_DASHBOARD_ANOMALY_FILTERS";
export const saveAnomalyFilters = () => (dispatch, getState) => {
    const state = getState();
    const userId = makeSelectUserId()(state);
    const dashboardAnomalySettings = makeSelectAnomalyFilter()(state);

    return dispatch({
        type: SAVE_DASHBOARD_ANOMALY_FILTERS,
        promise: updateUserData({
            userId,
            data: {
                user_metadata: {
                    dashboardAnomalySettings,
                },
            },
        }),
    });
};

/**
 *  EMAIL NOTIFICATION SETTINGS
 */
const SET_EMAIL_ANOMALY_FILTERS = "SET_EMAIL_ANOMALY_FILTERS";
export const setEmailAnomalyFilters = ({ anomalySettings }) => {
    return {
        type: SET_EMAIL_ANOMALY_FILTERS,
        value: anomalySettings,
    };
};

const SET_EMAIL_INSIGHTS_FILTERS = "SET_EMAIL_INSIGHTS_FILTERS";
export const setEmailInsightsFilters = ({ insightsSettings }) => {
    return {
        type: SET_EMAIL_INSIGHTS_FILTERS,
        value: insightsSettings,
    };
};

const SET_USER_SUGGESTION_NOTIFICATION_SETTINGS = "SET_USER_SUGGESTION_NOTIFICATION_SETTINGS";

export const setSuggestionNotificationSettings = (settings) => {
    return {
        type: SET_USER_SUGGESTION_NOTIFICATION_SETTINGS,
        payload: {
            settings,
        },
    };
};

const SET_EMAIL_WEEKLY_KPI_FILTERS = "SET_EMAIL_WEEKLY_KPI_FILTERS";
export const setEmailWeeklyKpiFilters = ({ weeklyKpiSettings }) => {
    return {
        type: SET_EMAIL_WEEKLY_KPI_FILTERS,
        value: weeklyKpiSettings,
    };
};

const SAVE_EMAIL_NOTIFICATIONS = "SAVE_EMAIL_NOTIFICATIONS";
export const saveEmailNotificationFilters = () => (dispatch, getState) => {
    const state = getState();
    const { user: { sub: userId, user_metadata: { emailNotificationSettings = {} } = {} } = {} } = state.user || {};

    return dispatch({
        type: SAVE_EMAIL_NOTIFICATIONS,
        promise: updateUserData({
            userId,
            data: {
                user_metadata: {
                    emailNotificationSettings,
                },
            },
        }),
    });
};

/**
 * Custom Metrics
 */
const SAVE_CUSTOM_METRICS = "SAVE_CUSTOM_METRICS";
export const saveCustomMetric = ({ id, name, formula, format, metrics, performance, changeInMonth }) => (
    dispatch,
    getState,
) => {
    const state = getState();
    const userId = makeSelectUserId()(state);
    return dispatch({
        type: SAVE_CUSTOM_METRICS,
        promise: MorphioAPI({
            url: "user/customMetrics",
            method: "PUT",
            data: {
                userId,
                id,
                name,
                formula,
                format,
                performance,
                changeInMonth,
                metrics,
            },
        }).then(({ data }) => data),
    });
};

const DELETE_CUSTOM_METRICS = "DELETE_CUSTOM_METRICS";
export const removeCustomMetric = ({ id }) => (dispatch, getState) => {
    const state = getState();
    const userId = makeSelectUserId()(state);
    return dispatch({
        type: DELETE_CUSTOM_METRICS,
        promise: MorphioAPI({
            url: "user/customMetrics",
            method: "DELETE",
            data: {
                userId,
                id,
            },
        }),
        meta: {
            id,
        },
    });
};

const DELETE_CONNECTION = "DELETE_CONNECTION";
export const deleteConnection = ({ platform, userId, connectionId }) => (dispatch, getState) => {
    return dispatch({
        type: DELETE_CONNECTION,
        promise: MorphioAPI({
            method: "DELETE",
            url: `/user/connection`,
            data: {
                connectionId,
                platform,
                userId,
            },
        }),
        meta: {
            connectionId,
            platform,
        },
    });
};

const INVITE_TEAM = "INVITE_TEAM";
export const inviteTeamAccess = ({ email, userId }) => (dispatch) => {
    return dispatch({
        type: INVITE_TEAM,
        promise: MorphioAPI({
            url: "user/sharedTeamAccess",
            method: "post",
            data: {
                userId,
                email,
            },
        }),
    });
};

const REVOKE_TEAM_ACCESS = "REVOKE_TEAM_ACCESS";
export const revokeTeamAccess = ({ sharingWithUserId, userId }) => (dispatch) => {
    return dispatch({
        type: REVOKE_TEAM_ACCESS,
        promise: MorphioAPI({
            url: "user/sharedTeamAccess",
            method: "delete",
            data: {
                userId,
                sharingWithUserId,
            },
        }),
        meta: {
            sharingWithUserId,
        },
    });
};

/**
 * REDUCERS
 */

const defaultState = {
    pending: false,
    testIntegrationsPending: false,
    childrenLoading: false,
    saveCustomMetricPending: false,
    createError: false,
    createLoading: false,
    updatePending: false,
    subscription: {},
    user: {},
    children: [],
};

export const user = createReducer(defaultState, {
    INITIALIZE_USER: (state, action) => {
        return {
            ...state,
            user: action.user,
        };
    },
    AUTH_CONNECTION: (state, action) => {
        const { id, platform } = action.payload;
        return {
            ...state,
            user: {
                ...state?.user,
                connections: {
                    ...state?.user?.connections,
                    [platform]: {
                        ...state?.user?.connections?.[platform],
                        [id]: {
                            name: `${channelFriendlyName(platform)} - ${id}`,
                            active: true,
                        },
                    },
                },
            },
        };
    },
    [DELETE_CONNECTION]: (state, action) =>
        handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                user: { ...prevState?.user, deleteConnectionPending: true },
            }),
            success: (prevState) => {
                const { connectionId, platform } = action.meta;
                const { [connectionId]: connectionDeleted, ...newConnections } = {
                    ...prevState?.user?.connections?.[platform],
                };

                return {
                    ...prevState,
                    user: {
                        ...prevState?.user,
                        connections: {
                            ...prevState?.user?.connections,
                            [platform]: newConnections,
                        },
                    },
                };
            },
            finish: (prevState) => ({
                ...prevState,
                user: { ...prevState?.user, deleteConnectionPending: false },
            }),
        }),
    USER_SYNC: (state, action) => {
        // To remove Universal Analytics
        const { analytics, ...newConnections } = action.payload.user.connections;
        return {
            ...state,
            user: {
                ...state.user,
                ...action.payload.user,
                connections: newConnections,
            },
        };
    },
    [CREATE_API_TOKEN]: (state, action) =>
        handle(state, action, {
            success: (prevState) => {
                const { data: { token } = {} } = action.payload || {};
                const { user = {} } = prevState.user || {};
                return {
                    ...prevState,
                    user: {
                        ...prevState.user,
                        apiToken: token,
                    },
                };
            },
        }),
    [GET_TICKET]: (state, action) =>
        handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                ticketPending: true,
            }),
            success: (prevState, { payload: { data } }) => ({
                ...prevState,
                ticket: data || {},
                ticketPending: false,
            }),
            failure: (prevState, { payload: { response: { data } = {} } = {} }) => ({
                ...prevState,
                ticket: data || {
                    status: 400,
                    message: "There has been an error.",
                },
                ticketPending: false,
            }),
        }),
    [GET_BILLING_DETAILS]: (state, action) =>
        handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                paymentSourcePending: true,
            }),
            finish: (prevState, { payload: { data: { data } = {} } = {} }) => ({
                ...prevState,
                paymentSource: data || {},
                paymentSourcePending: false,
            }),
        }),
    [SET_BILLING_DETAILS]: (state, action) =>
        handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                updateBillingDetailsPending: true,
                updateBillingDetailsError: "",
            }),
            failure: (prevState, { payload }) => {
                return {
                    ...prevState,
                    updateBillingDetailsPending: false,
                    updateBillingDetailsError: payload.response.data.message,
                };
            },
            success: (prevState, { payload: { data = {} } = {} }) => ({
                ...prevState,
                paymentSource: data || {},
                updateBillingDetailsPending: false,
                updateBillingDetailsError: "",
            }),
        }),
    [GET_INVOICES]: (state, action) =>
        handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                invoicesPending: true,
            }),
            finish: (prevState, { payload: { data } }) => ({
                ...prevState,
                invoices: data || [],
                invoicesPending: false,
            }),
        }),
    [SIGNIN_USER](state, action) {
        return handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                signingIn: true,
            }),
            success: (prevState, { payload: data }) => ({
                ...prevState,
                user: data,
            }),
            failure: (prevState, { payload: data }) => ({
                ...prevState,
                signInError: data,
            }),
            finish: (prevState) => ({
                ...prevState,
                signingIn: false,
            }),
        });
    },
    [GET_SUBSCRIPTION](state, action) {
        return handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                subscriptionLoading: true,
                subscription: {},
            }),
            success: (prevState, { payload: { subscription } }) => {
                return {
                    ...prevState,
                    subscription,
                };
            },
            failure: (prevState) => ({
                ...prevState,
                subscriptionError: true,
            }),
            finish: (prevState) => ({
                ...prevState,
                subscriptionLoading: false,
            }),
        });
    },
    [UPDATE_USER_NICKNAME](state, action) {
        return handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                updateUserNickNamePending: true,
            }),
            failure: (prevState) => ({
                ...prevState,
                updateUserNickNamePending: false,
            }),
            finish: (prevState) => ({
                ...prevState,
                updateUserNickNamePending: false,
            }),
        });
    },
    [UPDATE_COMPANY_COLOR](state, action) {
        return handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                updateColorPending: true,
            }),
            failure: (prevState) => ({
                ...prevState,
                updateColorPending: false,
            }),
            finish: (prevState) => ({
                ...prevState,
                updateColorPending: false,
            }),
        });
    },
    [UPDATE_COMPANY](state, action) {
        return handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                updateCompanyPending: true,
            }),
            failure: (prevState) => ({
                ...prevState,
                updateCompanyPending: false,
            }),
            finish: (prevState) => ({
                ...prevState,
                updateCompanyPending: false,
            }),
        });
    },
    [UPDATE_TIMEZONE](state, action) {
        return handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                updateTimezonePending: true,
            }),
            failure: (prevState) => ({
                ...prevState,
                updateTimezonePending: false,
            }),
            finish: (prevState) => ({
                ...prevState,
                updateTimezonePending: false,
            }),
        });
    },
    [UPDATE_BILLING_EMAIL](state, action) {
        return handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                updateBillingEmailPending: true,
            }),
            failure: (prevState) => ({
                ...prevState,
                updateBillingEmailPending: false,
            }),
            success: (prevState) => ({
                ...prevState,
                subscription: {
                    ...prevState.subscription,
                    billingEmail: action.payload.email,
                },
                updateBillingEmailPending: false,
            }),
        });
    },
    [UPDATE_USER_AVATAR](state, action) {
        return handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                updateUserAvatarPending: true,
            }),
            failure: (prevState) => ({
                ...prevState,
                updateUserAvatarPending: false,
            }),
            finish: (prevState) => ({
                ...prevState,
                updateUserAvatarPending: false,
            }),
        });
    },
    [CLEAR_USER_AVATAR](state, action) {
        return handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                clearAvatarPending: true,
            }),
            failure: (prevState) => ({
                ...prevState,
                clearAvatarPending: false,
            }),
            finish: (prevState) => ({
                ...prevState,
                clearAvatarPending: false,
            }),
        });
    },
    [UPDATE_COMPANY_LOGO](state, action) {
        return handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                updateCompanyLogoPending: true,
            }),
            failure: (prevState) => ({
                ...prevState,
                updateCompanyLogoPending: false,
            }),
            finish: (prevState) => ({
                ...prevState,
                updateCompanyLogoPending: false,
            }),
        });
    },
    [CLEAR_COMPANY_LOGO](state, action) {
        return handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                clearCompanyLogoPending: true,
            }),
            failure: (prevState) => ({
                ...prevState,
                clearCompanyLogoPending: false,
            }),
            finish: (prevState) => ({
                ...prevState,
                clearCompanyLogoPending: false,
            }),
        });
    },
    [POPULATE_USER](state, action) {
        return {
            ...state,
            user: merge(state.user, action.user),
        };
    },
    [TEST_INTEGRATIONS_PENDING](state, action) {
        return {
            ...state,
            testIntegrationsPending: action.pending,
        };
    },
    [RELOAD_USER_USAGE](state, action) {
        return handle(state, action, {
            success: (prevState, { payload }) => {
                return {
                    ...prevState,
                    user: {
                        ...prevState.user,
                        ...payload,
                    },
                };
            },
        });
    },
    [UPDATE_PROMOCODE](state, action) {
        return handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                updatePromoCodePending: true,
            }),
            failure: (prevState) => ({
                ...prevState,
                updatePromoCodePending: false,
            }),
            success: (prevState) => ({
                ...prevState,
                user: {
                    ...prevState.user,
                    bypassCreditCard: true,
                },
                updatePromoCodePending: false,
            }),
        });
    },
    [CANCEL_SUBSCRIPTION](state, action) {
        return handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                cancelSubscriptionPending: true,
            }),
            finish: (prevState) => ({
                ...prevState,
                cancelSubscriptionPending: false,
            }),
        });
    },
    /**
     * APPSUMO
     */
    [ADD_APPSUMO_CODE]: (state, action) =>
        handle(state, action, {
            success: (prevState) => {
                const { user: { user_metadata: userMetadata = {} } = {} } = prevState;

                const { appSumoCodes } = action.payload;

                return {
                    ...prevState,
                    user: {
                        ...prevState.user,
                        user_metadata: {
                            ...userMetadata,
                            appSumoCodes,
                        },
                    },
                };
            },
        }),
    /**
     * TOGGLE NAVIGATION
     */
    [TOGGLE_NAVIGATION]: (state, action) =>
        handle(state, action, {
            start: (prevState) => {
                const { user: { user_metadata: { isNavOpen = true, ...userMetadata } = {} } = {} } = prevState;
                return {
                    ...prevState,
                    user: {
                        ...prevState.user,
                        user_metadata: {
                            ...userMetadata,
                            isNavOpen: !isNavOpen,
                        },
                    },
                };
            },
        }),
    /**
     *  ANOMALIES
     */
    [SET_DASHBOARD_ANOMALY_FILTERS](state, action) {
        const { user: { user_metadata: { dashboardAnomalySettings = {}, ...userMetadata } = {} } = {} } = state;

        return {
            ...state,
            user: {
                ...state.user,
                user_metadata: {
                    ...userMetadata,
                    dashboardAnomalySettings: {
                        ...dashboardAnomalySettings,
                        ...action.value,
                    },
                },
            },
        };
    },
    [SAVE_DASHBOARD_ANOMALY_FILTERS]: (state, action) =>
        handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                updatingDashboardAnomalyFilters: true,
            }),
            finish: (prevState) => ({
                ...prevState,
                updatingDashboardAnomalyFilters: false,
            }),
        }),
    /*
     * NOTIFICATIONS
     */
    [SET_EMAIL_ANOMALY_FILTERS](state, action) {
        const {
            user: {
                user_metadata: {
                    emailNotificationSettings: { anomalySettings: oldAnomalySettings = {}, ...emailSettings } = {},
                    ...userMetadata
                } = {},
            } = {},
        } = state;

        return {
            ...state,
            user: {
                ...state.user,
                user_metadata: {
                    ...userMetadata,
                    emailNotificationSettings: {
                        ...emailSettings,
                        anomalySettings: {
                            ...oldAnomalySettings,
                            ...action.value,
                        },
                    },
                },
            },
        };
    },
    [SET_USER_SUGGESTION_NOTIFICATION_SETTINGS](state, action) {
        return merge({}, state, {
            user: {
                user_metadata: {
                    emailNotificationSettings: {
                        insightsSettings: action.payload.settings,
                    },
                },
            },
        });
    },
    [SET_EMAIL_INSIGHTS_FILTERS](state, action) {
        const {
            user: {
                user_metadata: {
                    emailNotificationSettings: { insightsSettings: oldInsightsSettings = {}, ...emailSettings } = {},
                    ...userMetadata
                } = {},
            } = {},
        } = state;

        return {
            ...state,
            user: {
                ...state.user,
                user_metadata: {
                    ...userMetadata,
                    emailNotificationSettings: {
                        ...emailSettings,
                        insightsSettings: {
                            ...oldInsightsSettings,
                            ...action.value,
                        },
                    },
                },
            },
        };
    },
    [SET_EMAIL_WEEKLY_KPI_FILTERS](state, action) {
        const {
            user: {
                user_metadata: {
                    emailNotificationSettings: { weeklyKpiSettings: oldWeeklyKpiSettings = {}, ...emailSettings } = {},
                    ...userMetadata
                } = {},
            } = {},
        } = state;

        return {
            ...state,
            user: {
                ...state.user,
                user_metadata: {
                    ...userMetadata,
                    emailNotificationSettings: {
                        ...emailSettings,
                        weeklyKpiSettings: {
                            ...oldWeeklyKpiSettings,
                            ...action.value,
                        },
                    },
                },
            },
        };
    },
    [SAVE_EMAIL_NOTIFICATIONS]: (state, action) =>
        handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                saveEmailNotificationsPending: true,
            }),
            finish: (prevState) => ({
                ...prevState,
                saveEmailNotificationsPending: false,
            }),
        }),
    [UPDATE_SEEN_IT]: (state, action) => {
        return merge({}, state, {
            user: {
                user_metadata: {
                    seen_it: {
                        [action.it]: {
                            seen: action.seen,
                            updated: action.updated,
                        },
                    },
                },
            },
        });
    },
    [UPDATE_SETUP_GUIDE_TOGGLE]: (state, action) => {
        return merge({}, state, {
            user: {
                user_metadata: {
                    setup_guide_toggle: {
                        [action.accountId]: action.isOpen,
                    },
                },
            },
        });
    },
    [UPDATE_HAS_BOOKED_MEETING]: (state, action) => {
        return merge({}, state, {
            user: {
                user_metadata: {
                    has_booked_meeting: action.hasBooked,
                },
            },
        });
    },
    [UPDATE_SETUP_GUIDE_DISMISSED]: (state, action) => {
        return merge({}, state, {
            user: {
                user_metadata: {
                    setup_guide_dismissed: {
                        [action.it]: {
                            dismissed: action.dismissed,
                            updated: action.updated,
                        },
                    },
                },
            },
        });
    },
    /**
     * Custom Metrics
     */
    [SAVE_CUSTOM_METRICS]: (state, action) =>
        handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                saveCustomMetricPending: true,
            }),
            finish: (prevState) => {
                const { id } = action.payload;

                return {
                    ...prevState,
                    user: {
                        ...prevState.user,
                        customMetrics: {
                            ...prevState.user.customMetrics,
                            [id]: action.payload,
                        },
                    },
                    saveCustomMetricPending: false,
                };
            },
        }),
    [DELETE_CUSTOM_METRICS]: (state, action) =>
        handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                saveCustomMetricPending: true,
            }),
            finish: (prevState) => {
                const { id } = action.meta;
                const { [id]: customMetricDeleted, ...customMetrics } = prevState.user.customMetrics;

                return {
                    ...prevState,
                    user: {
                        ...prevState.user,
                        customMetrics: customMetrics,
                    },
                    saveCustomMetricPending: false,
                };
            },
        }),
    [UPDATE_USER_CONTROL_PANEL_SETTINGS]: (state, action) => {
        const { controlPanelSettings = {} } = state.user.user_metadata;

        return {
            ...state,
            user: {
                ...state.user,
                user_metadata: {
                    ...state.user.user_metadata,
                    controlPanelSettings: {
                        ...controlPanelSettings,
                        ...action.meta.settings,
                    },
                },
            },
        };
    },
    [UPDATE_CONTROL_PANEL_OVERVIEW_UI]: (state, action) => {
        const { controlPanelSettings = {} } = state.user.user_metadata;
        const { ui = {} } = controlPanelSettings;

        return {
            ...state,
            user: {
                ...state.user,
                user_metadata: {
                    ...state.user.user_metadata,
                    controlPanelSettings: {
                        ...controlPanelSettings,
                        ui: {
                            ...ui,
                            ...action.ui,
                        },
                    },
                },
            },
        };
    },
    [UPDATE_DASHBOARD_FILTERS]: (state, action) => {
        const { data = {}, accountId = "" } = action.meta || {};
        const { dashboardFilters = {} } = state.user.user_metadata;

        return {
            ...state,
            user: {
                ...state.user,
                user_metadata: {
                    ...state.user.user_metadata,
                    dashboardFilters: {
                        ...dashboardFilters,
                        [accountId]: { ...(dashboardFilters[accountId] || {}), ...data },
                    },
                },
            },
        };
    },
    /**
     * WORKSPACE
     */
    [UPDATE_SELECTED_WORKSPACE]: (state, action) => {
        const currentWorkspaces = state.user.app_metadata?.workspaces || {};
        return {
            ...state,
            user: {
                ...state.user,
                app_metadata: {
                    ...state.user.app_metadata,
                    workspaces: {
                        ...Object.keys(currentWorkspaces).reduce((cache, id) => {
                            return {
                                ...cache,
                                [id]: {
                                    ...currentWorkspaces[id],
                                    selected: false,
                                },
                            };
                        }, {}),
                        [action.workspaceId]: {
                            ...currentWorkspaces?.[action.workspaceId],
                            selected: true,
                        },
                    },
                },
            },
        };
    },
    [INVITE_TEAM]: (state, action) =>
        handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                sharedTeamAccessPending: true,
            }),
            success: (state) => {
                return {
                    ...state,
                    user: {
                        ...state.user,
                        app_metadata: {
                            ...state.user.app_metadata,
                            sharedTeamAccess: {
                                ...(state.user.app_metadata?.sharedTeamAccess || {}),
                                ...action.payload.data,
                            },
                        },
                    },
                };
            },
            finish: (prevState) => ({
                ...prevState,
                sharedTeamAccessPending: false,
            }),
        }),
    [REVOKE_TEAM_ACCESS]: (state, action) =>
        handle(state, action, {
            start: (prevState) => ({
                ...prevState,
                sharedTeamAccessPending: true,
            }),
            success: (state) => {
                const { [action.meta.sharingWithUserId]: deleted, ...newSharedTeamAccess } = {
                    ...(state.user.app_metadata?.sharedTeamAccess || {}),
                };
                return {
                    ...state,
                    user: {
                        ...state.user,
                        app_metadata: {
                            ...state.user.app_metadata,
                            sharedTeamAccess: newSharedTeamAccess,
                        },
                    },
                };
            },
            finish: (prevState) => ({
                ...prevState,
                sharedTeamAccessPending: false,
            }),
        }),
    /**
     *  END
     */
});
