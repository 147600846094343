import { getSelectedWorkspaceId } from "lib/workspaces";
import { MorphioAPI, getProfile } from "../../lib/api";

export const resetUserAnomalyFiltersData = ({ userId, accountId }) =>
    MorphioAPI({
        url: "user/account/anomalySettings",
        method: "GET",
        query: {
            userId,
            accountId,
        },
    });

export const createApiTokenData = ({ userId }) =>
    MorphioAPI({
        url: "user/apiToken",
        method: "POST",
        data: {
            userId,
        },
    });

export const updateBillingEmailData = ({ userId, email }) =>
    MorphioAPI({
        url: "user/billing/email",
        method: "PUT",
        data: {
            userId,
            email,
        },
    });

export const changeUserPasswordRequest = ({ password, newPassword, userId, email }) =>
    MorphioAPI({
        url: "user/changePassword",
        method: "POST",
        data: {
            password,
            newPassword,
            userId,
            email,
        },
    });

export const removeSubscriptionDetails = () =>
    MorphioAPI({
        url: "user/subscription",
        method: "DELETE",
    });

export const removeUser = ({ userId }) =>
    MorphioAPI({
        url: "user",
        method: "DELETE",
        data: {
            userId,
        },
    });

export const fetchTicket = ({ ticketId } = {}) =>
    MorphioAPI({
        url: `user/ticket?ticketId=${ticketId}`,
        method: "GET",
    });

export const storeSubscriptionDetails = ({ plan, promo } = {}) => {
    return MorphioAPI({
        url: "user/subscription",
        method: "PUT",
        data: {
            plan,
            promo,
        },
    });
};

export const storeBillingDetails = ({ token, userId } = {}) =>
    MorphioAPI({
        url: "user/billing",
        method: "PUT",
        data: {
            token,
            userId,
        },
    });

export const fetchBillingDetails = (userId = "") =>
    MorphioAPI({
        url: `user/billing?userId=${userId}`,
    });

export const fetchInvoices = () =>
    MorphioAPI({
        url: "user/invoices",
    });

export const register = ({ email, company, name, password, plan, hasTrial } = {}) => {
    return MorphioAPI({
        url: "register",
        method: "POST",
        data: {
            email,
            company,
            name,
            password,
            plan,
            hasTrial,
        },
    });
};

export const updatePassword = ({ ticketId, password } = {}) =>
    MorphioAPI({
        url: "user/updatePassword",
        method: "POST",
        data: {
            ticketId,
            password,
        },
    });

export const resetPasswordData = ({ email } = {}) =>
    MorphioAPI({
        url: "user/resetPassword",
        method: "POST",
        data: {
            email,
        },
    });

export const updateAppSumoCode = ({ userId, appSumoCode }) => {
    return MorphioAPI({
        url: `appsumo/upgrade`,
        method: "post",
        data: {
            appSumoCode,
            userId,
        },
    }).then(({ data }) => data);
};

export const getSubscription = () => {
    const workspaceId = getSelectedWorkspaceId();
    return MorphioAPI({
        url: `/user/self?workspaceId=${workspaceId}`,
        method: "get",
    }).then(({ data }) => data);
};

export const reloadUserUsageData = () => {
    const workspaceId = getSelectedWorkspaceId();
    return MorphioAPI({
        url: `/user/self?workspaceId=${workspaceId}`,
        method: "get",
    }).then(({ data }) => {
        const { numberOfCompetitors, numberOfUsers, numberOfViews } = data;
        return {
            numberOfCompetitors,
            numberOfUsers,
            numberOfViews,
        };
    });
};

export const getParentMetadata = ({ parentUserId }) => {
    if (!parentUserId) {
        return Promise.resolve({});
    }

    return MorphioAPI({
        url: `user/parent?parentUserId=${parentUserId}`,
        method: "get",
    })
        .then(({ data }) => data)
        .catch((err) => {
            console.log(err);
            return {};
        });
};

export const createTag = ({ parentId, tagId, tagName }) => {
    return MorphioAPI({
        url: `user/tags/create`,
        method: "post",
        data: {
            userId: parentId,
            tagId,
            tagName,
        },
    }).then(({ data }) => data);
};

export const editTag = ({ parentId, tagId, tagName }) => {
    return MorphioAPI({
        url: `user/tags/edit`,
        method: "post",
        data: {
            userId: parentId,
            tagId,
            tagName,
        },
    }).then(({ data }) => data);
};

export const deleteTag = ({ parentId, tagId }) => {
    return MorphioAPI({
        url: `user/tags/delete`,
        method: "post",
        data: {
            userId: parentId,
            tagId,
        },
    }).then(({ data }) => data);
};

export const getRolesData = () => {
    return MorphioAPI({
        url: "user/roles",
        method: "get",
    }).then(({ data }) => data);
};

export const updateUserData = ({ data = {}, userId, replace = false }) => {
    return MorphioAPI({
        url: `user`,
        method: "put",
        data: {
            data,
            userId,
            replace,
        },
    }).then(({ data }) => data);
};

export const updateUserAvatarRequest = ({ fileFormat, userId, file }) => {
    return MorphioAPI({
        url: `user/avatar`,
        method: "post",
        data: {
            userId,
            fileFormat,
            file,
        },
    }).then(({ data }) => data);
};

export const deleteUserAvatarRequest = ({ fileFormat, userId, file }) => {
    return MorphioAPI({
        url: `user/avatar`,
        method: "delete",
        data: {
            userId,
        },
    }).then(({ data }) => data);
};

export const updateCompanyColor = ({ color, userId }) => {
    return MorphioAPI({
        url: `user/branding/color`,
        method: "put",
        data: {
            userId,
            color,
        },
    }).then(({ data }) => data);
};

export const updateCompanyLogoRequest = ({ fileFormat, userId, file }) => {
    return MorphioAPI({
        url: `user/branding/logo`,
        method: "post",
        data: {
            userId,
            fileFormat,
            file,
        },
    }).then(({ data }) => data);
};

export const deleteCompanyLogoRequest = ({ fileFormat, userId, file }) => {
    return MorphioAPI({
        url: `user/branding/logo`,
        method: "delete",
        data: {
            userId,
        },
    }).then(({ data }) => data);
};

export const updateUserSite = ({ data, userId }) => {
    return MorphioAPI({
        url: `user/account?user_id=${userId}`,
        method: "put",
        data,
    }).then((res) => res.data);
};

export const createUserData = ({ name, projectName, email, role, password, permissions = [] }) => {
    const parentUserId = getProfile()?.sub;
    // TODO: Add a better varification on form
    return MorphioAPI({
        url: "user",
        method: "post",
        data: {
            name,
            email,
            password,
            permissions,
            role,
            projectName,
            parentUserId,
        },
    }).then((res) => res.data);
};

export const inviteUserRequest = ({ name, projectName, email, role, permissions = [] }) => {
    const parentUserId = getProfile()?.sub;
    // TODO: Add a better varification on form
    return MorphioAPI({
        url: "user/invitation",
        method: "post",
        data: {
            name,
            email,
            permissions,
            role,
            projectName,
            parentUserId,
        },
    }).then((res) => res.data);
};

export const favouriteView = ({ pinned, accountId, userId }) => {
    return MorphioAPI({
        url: "user/favourite",
        method: "put",
        data: {
            userId,
            accountId,
            pinned,
        },
    });
};
