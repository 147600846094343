import { compose } from "recompose";
import { connect } from "react-redux";
import { formValueSelector, reduxForm, SubmissionError } from "redux-form";
import v from "validate.js";

import CreditCard from "./";
import { setBillingDetails, setSubscriptionDetails } from "_redux/users";
import { mixpanel } from "components/Mixpanel";
import {
    makeSelectSubscription,
    makeSelectType,
    makeSelectUserId,
    selectPaymentSource,
    makeSelectSettingBillingDetails,
    makeSelectFailedSettingBillingDetails,
} from "_redux/users/selectors";
import { makeSelectNumberOfAccounts } from "features/ControlPanel/ducks/selectors";
import { selectCouponCode, selectPlanInfo } from "features/PlanOptions/_ducks/selectors";
import { actions as planOptionActions } from "features/PlanOptions/_ducks";

const { togglePlanOptions, fetchPromoDetails, setPromoDetails } = planOptionActions;

const makeMapStateToProps = () => {
    const numberOfAccountSelector = makeSelectNumberOfAccounts();
    const typeSelector = makeSelectType();
    const userIdSelector = makeSelectUserId();
    const subscriptionSelector = makeSelectSubscription();

    return (state, { onCompletePayment }) => {
        const { address: addressObj, name: cardholder } = selectPaymentSource(state);
        const { city, country, line1: address, postal_code: postalCode, state: region } = addressObj || {};

        const { form } = state;

        const numberOfViews = numberOfAccountSelector(state);
        const planInfo = selectPlanInfo(state);
        const selector = formValueSelector("upgradePlan");
        const selectedPlan = selector({ form }, "planType") || null;
        const selectedPlanCycle = selector({ form }, "planCycle") || "yearly";
        const coupon = selector({ form }, "coupon");
        const userType = typeSelector(state) || "regular";

        const { id: existingPlan, interval: existingInterval } = subscriptionSelector(state);

        let {
            promoId,
            amount_off = 0,
            percent_off = 0,
            loading: couponDataLoading = false,
            coupon: appliedCoupon,
            error: couponError = "",
        } = selectCouponCode(state);

        if (selectedPlanCycle === "yearly") {
            promoId = process.env.REACT_APP_COUPON_YEARLY;
            percent_off = 30;
        }
        if (selectedPlanCycle === "quarterly") {
            promoId = process.env.REACT_APP_COUPON_QUARTERLY;
            percent_off = 10;
        }

        return {
            userId: userIdSelector(state),
            planInfo,
            selectedPlan,
            selectedPlanCycle,
            showCCDisclaimer: userType.includes("appSumoUser") && !cardholder,
            onCompletePayment,
            initialValues: {
                address,
                cardholder,
                city,
                country,
                postalCode,
                region,
                planCycle: "monthly",
                planType: process.env.REACT_APP_PLAN_PRO_1_MONTHLY,
            },
            coupon,
            promoId,
            percentOff: percent_off / 100,
            amount_off: amount_off / 100,
            couponDataLoading,
            couponAllowed: true,
            appliedCoupon,
            couponError,
            existingPlan: existingPlan && existingPlan !== "freemium" ? existingPlan : "",
            existingInterval: existingInterval && existingPlan !== "freemium" ? existingInterval : "",
            numberOfViews,
            userType,
            isSettingBillingDetails: makeSelectSettingBillingDetails(state),
            isSettingBillingDetailsFailed: makeSelectFailedSettingBillingDetails(state),
        };
    };
};

const mapDispatchToProps = (dispatch) => ({
    togglePlanOptions: (isOpen, step) => dispatch(togglePlanOptions({ isOpen, step })),
    fetchPromoDetails: (coupon) => dispatch(fetchPromoDetails(coupon)),
    setPromoDetails: (details) => dispatch(setPromoDetails(details)),
});

const validate = (values) => {
    const errors = v(values, {
        address: {
            presence: {
                message: "Required",
            },
        },
        cardholder: {
            presence: {
                message: "Required",
            },
        },
        city: {
            presence: {
                message: "Required",
            },
        },
        country: {
            presence: {
                message: "Required",
            },
        },
        postalCode: {
            presence: {
                message: "Required",
            },
        },
        region: {
            presence: {
                message: "Required",
            },
        },
        planCycle: {
            presence: {
                message: "Please select a paymed period",
            },
        },
        planType: {
            presence: {
                message: "Please select a plan type",
            },
        },
    });

    return {
        ...errors,
        cardNumber: !values.cardNumber || values.cardNumber.complete === false ? "Valid card number is required" : "",
        cardExpiry: !values.cardExpiry || values.cardExpiry.complete === false ? "Valid expiry date is required" : "",
        cardCVC: !values.cardCVC || values.cardCVC.complete === false ? "Valid CVC is required" : "",
    };
};

const submit = async (
    { address, cardholder, city, country, postalCode, region, planType },
    dispatch,
    { stripe, userId, plans, promoId },
) => {
    // Add CC in stripe
    const { error, source } = await stripe.createSource({
        type: "card",
        owner: {
            address: {
                city,
                country,
                line1: address,
                postal_code: postalCode,
                state: region,
            },
            name: cardholder,
        },
    });

    if (error) {
        throw new SubmissionError({
            _error: error.message,
        });
    }

    // Sends to API, which updates stripe
    const subsciptionDetails = await dispatch(setSubscriptionDetails({ plan: planType, promo: promoId }));

    if (subsciptionDetails.error) {
        throw new SubmissionError({
            _error: subsciptionDetails.payload.response.data.message,
        });
    }

    const billingDetails = await dispatch(setBillingDetails({ token: source.id, userId }));

    if (billingDetails.error) {
        throw new SubmissionError({
            _error: billingDetails.payload.response.data.message,
        });
    }

    mixpanel.people.set({
        "Credit Card": true,
        "Is Trial": false,
        Subscription: plans[planType]?.name,
        "Account Limit": plans[planType]?.numberOfViews,
        "Pricing ID": planType,
    });
    mixpanel.identify(userId);
    mixpanel.track("Credit Card Added", {
        "Plan Option Overlay": true,
    });
    mixpanel.track("Plan Upgraded", {
        Location: "Wall",
        "Plan Name": plans[planType]?.name,
        "Plan Account Limit": plans[planType]?.numberOfViews,
        "Plan Pricing ID": planType,
    });

    return true;
};

export default compose(
    connect(makeMapStateToProps, mapDispatchToProps),
    reduxForm({
        form: "upgradePlan",
        enableReinitialize: true,
        onSubmit: submit,
        validate,
    }),
)(CreditCard);
