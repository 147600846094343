import { createSelector } from "reselect";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import { tagColors, hexToRGB } from "lib/accountTags";

const selectUser = ({ user = {} } = {}) => {
    return user.user || {};
};

const selectUserObject = ({ user = {} } = {}) => {
    return user || {};
};

const selectPaymentSource = ({ user = {} } = {}) => {
    return user.paymentSource || {};
};

export const makeSelectCancelSubscriptionPending = createSelector(
    selectUserObject,
    ({ cancelSubscriptionPending }) => cancelSubscriptionPending,
);

//********************* */
//
//  User Info
//
//********************* */

const makeSelectUserId = () => createSelector(selectUser, ({ sub }) => sub);

const userIdSelector = createSelector(selectUser, ({ sub }) => sub);

const makeSelectIsGodUser = () =>
    createSelector(selectUser, ({ sub, administrative }) => {
        const allowedUsers = [
            // Production dev@morphio.ai
            "auth0|5b9991de6a52865dc2e3b8b9",
            // Prototype access@morphio.ai
            "google-oauth2|101396170388971152673",
        ];

        return allowedUsers.includes(sub) && administrative;
    });

const makeSelectRole = () => createSelector(selectUser, ({ app_metadata = {} }) => app_metadata.role);

const makeSelectEmail = () => createSelector(selectUser, ({ email = {} }) => email);

const makeSelectStripeCustomerId = () => createSelector(selectUser, ({ stripeCustomerID = {} }) => stripeCustomerID);

export const makeSelectSettingBillingDetails = createSelector(
    selectUserObject,
    ({ updateBillingDetailsPending }) => updateBillingDetailsPending,
);

export const makeSelectFailedSettingBillingDetails = createSelector(
    selectUserObject,
    ({ updateBillingDetailsError }) => updateBillingDetailsError,
);

const makeSelectUserPicture = () => createSelector(selectUser, ({ picture = {} }) => picture);

const makeSelectLoginCount = () => createSelector(selectUser, ({ loginCount = {} }) => loginCount);

export const selectParentUserId = createSelector(selectUser, ({ app_metadata: { parent = "" } = {} }) => parent);

export const makeSelectUserConnections = () => createSelector(selectUser, ({ connections = {} }) => connections);
export const makeSelectCustomMetrics = () => createSelector(selectUser, ({ customMetrics = {} }) => customMetrics);

export const makeSelectCustomDefaultMetrics = () =>
    createSelector(selectUser, ({ customMetrics = {} }) =>
        Object.keys(customMetrics).reduce((cache, metric) => {
            if (!customMetrics[metric].isDefault) {
                return cache;
            }

            return {
                ...cache,
                [metric]: customMetrics[metric],
            };
        }, {}),
    );

export const makeSelectCustomDefaultMetricIds = () =>
    createSelector(makeSelectCustomDefaultMetrics(), (metrics) => Object.keys(metrics).map((metric) => metric));

export const makeSelectSignUpDate = () => createSelector(selectUser, ({ created_at = "2020-03-01" }) => created_at);

/**
 * Checks if user role is the one passed in
 * @param {string} isRole
 *
 * @return {boolean} Boolean - is user role
 */

const makeSelectIsRole = (isRole) =>
    createSelector(makeSelectRole(), (userRole) => {
        return isRole === userRole;
    });

const makeSelectIsReportingRole = () =>
    createSelector(makeSelectRole(), (userRole) => {
        return "reporting" === userRole;
    });

//********************* */
//
//  Subscription
//
//********************* */
const makeSelectSubscription = () =>
    createSelector(
        ({ user: { subscription = {} } = {} } = {}) => subscription,
        (subscription) => {
            return subscription;
        },
    );

export const makeSelectCoupon = () => createSelector(makeSelectSubscription(), ({ coupon }) => coupon || {});

const makeSelectSubscriptionPlanId = () =>
    createSelector(makeSelectSubscription(), ({ mainPlanId, id = "" }) => id || mainPlanId);
export const makeSelectSubscriptionType = () => createSelector(makeSelectSubscription(), ({ type = "" }) => type);

const makeSelectHasSubsciption = () => createSelector(makeSelectSubscription(), ({ exists = false }) => exists);

export const makeSelectIsTrial = () => createSelector(makeSelectSubscription(), ({ isTrial }) => isTrial);

export const makeSelectIsFree = () => createSelector(makeSelectSubscription(), ({ isFree }) => isFree);

export const makeSelectType = () => createSelector(selectUser, ({ type }) => type || "");

export const makeSelectUserActiveFeatures = () =>
    createSelector(
        selectUser,
        ({ activeFeatures }) =>
            activeFeatures || {
                reporting: true,
                insights: true,
                monitoring: true,
            },
    );

export const makeSelectAppSeoCodes = () =>
    createSelector(makeSelectUserMetaData(), ({ appSumoCodes = {} } = {}) => appSumoCodes || {});

export const makeSelectDaysRemainingInTrial = () =>
    createSelector(makeSelectSubscription(), ({ isTrial, trialExpiry }) => {
        return isTrial && Math.ceil(moment.duration(moment(trialExpiry).diff(moment())).asDays());
    });

export const makeSelectNumberOfAllowedCompetitors = () =>
    createSelector(makeSelectSubscription(), ({ numberOfCompetitors }) => numberOfCompetitors || 5);

export const makeSelectNumberOfAllowedKeywords = () =>
    createSelector(makeSelectSubscription(), ({ numberOfKeywords }) => numberOfKeywords || 10);

export const makeSelectNumberOfAllowedAccounts = () =>
    createSelector(makeSelectSubscription(), ({ numberOfViews }) => numberOfViews || 10);

//********************* */
//
//  User Meta Data
//
//********************* */

export const makeSelectUserMetaData = () => createSelector(selectUser, ({ user_metadata = {} }) => user_metadata);

export const makeSelectUserSuggestionNotificationSettings = () =>
    createSelector(
        makeSelectUserMetaData(),
        ({ emailNotificationSettings: { insightsSettings } = {} }) => insightsSettings,
    );

export const selectUserNotificationSettings = createSelector(
    makeSelectUserMetaData(),
    ({ emailNotificationSettings }) => emailNotificationSettings,
);

export const makeSelectUserAccountFavourites = () =>
    createSelector(makeSelectUserMetaData(), ({ favourite }) => favourite || {});

export const makeSelectUserDashboardFilters = () =>
    createSelector(makeSelectUserMetaData(), ({ dashboardFilters }) => dashboardFilters || {});

export const makeSelectUserName = () => createSelector(makeSelectUserMetaData(), ({ nickname }) => nickname);

export const makeSelectUserDowngrade = () =>
    createSelector(makeSelectUserMetaData(), ({ freemiumDowngrade }) => freemiumDowngrade);

export const makeSelectUserTrialDismiss = () =>
    createSelector(makeSelectUserMetaData(), ({ trialWallDismiss }) => trialWallDismiss);

const makeSelectNavState = () => createSelector(makeSelectUserMetaData(), ({ isNavOpen = true } = {}) => isNavOpen);

export const selectQuickStartState = createSelector(
    makeSelectUserMetaData(),
    ({ isQuickStartGuideOpen = true } = {}) => isQuickStartGuideOpen,
);

export const makeSelectAnomalyFilter = () =>
    createSelector(makeSelectUserMetaData(), ({ dashboardAnomalySettings }) => dashboardAnomalySettings);

export const makeSelectAnomalyFilterUpdating = () =>
    createSelector(selectUser, ({ updatingDashboardAnomalyFilters }) => updatingDashboardAnomalyFilters);

export const selectSeenIt = createSelector(makeSelectUserMetaData(), ({ seen_it = {} }) =>
    Object.keys(seen_it).reduce((cache, it) => ({ ...cache, [it]: seen_it[it] }), {}),
);
export const selectSeenItById = createSelector([selectSeenIt, (_, id) => id], (seenItList, id) => seenItList[id]);

export const selectSetupGuideDismissed = createSelector(makeSelectUserMetaData(), ({ setup_guide_dismissed = {} }) =>
    Object.keys(setup_guide_dismissed).reduce((cache, it) => ({ ...cache, [it]: setup_guide_dismissed[it] }), {}),
);

export const selectSetupGuideToggle = createSelector(
    makeSelectUserMetaData(),
    ({ setup_guide_toggle = {} }) => setup_guide_toggle,
);

export const selectHasBookedMeeting = createSelector(
    makeSelectUserMetaData(),
    ({ has_booked_meeting = false }) => has_booked_meeting,
);

export const selectControlPanelSettings = createSelector(
    makeSelectUserMetaData(),
    ({ controlPanelSettings = {} }) => controlPanelSettings,
);

export const selectSavedMetrics = createSelector(selectControlPanelSettings, ({ metrics = {} }) => {
    const defaultMetrics = [
        "custom-cost",
        "custom-clicks",
        "custom-conversions",
        "analyticsV4-sessions",
        "custom-paid-clicks",
    ];

    // If user specifically removed metrics and saved, value will be 0
    if (isEmpty(metrics) && metrics !== 0) {
        return defaultMetrics;
    }

    return Array.isArray(metrics) ? metrics : Object.values(metrics || {});
});

export const selectSavedTagsUnformatted = createSelector(selectControlPanelSettings, ({ tags = [] }) => tags);

export const selectSavedTags = createSelector(selectControlPanelSettings, ({ tags = [] }) => {
    let colorCount = 0;

    return Object.keys(tags).map((tag, index) => {
        const tagColor = tagColors[colorCount];
        const tagName = tags[tag].name;
        const tagId = tags[tag].id;
        colorCount = colorCount + 1 >= tagColors.length ? 0 : colorCount + 1;

        return {
            id: tagId,
            name: tagName,
            hexColor: tagColor,
            color: hexToRGB({ hex: tagColor, opacity: 0.12 }),
            index,
        };
    });
});

export const selectUserMetaOverviewUi = createSelector(selectControlPanelSettings, ({ ui = {} }) => ui);

export const makeSelectWorkspaces = () =>
    createSelector(selectUser, ({ user_metadata: { company } = {}, app_metadata: { workspaces = {} } = {} }) => {
        if (!workspaces[null]) {
            workspaces[null] = {
                name: company || "Default",
                isDefault: true,
                selected: true,
            };
        }
        return workspaces;
    });

export const makeSelectSharedTeamAccess = () =>
    createSelector(selectUser, ({ app_metadata: { sharedTeamAccess = {} } = {} }) => sharedTeamAccess);

export const makeSelectSharedTeamAccessPending = () =>
    createSelector(selectUser, ({ sharedTeamAccessPending = {} }) => sharedTeamAccessPending);

export {
    makeSelectIsRole,
    makeSelectIsReportingRole,
    makeSelectRole,
    selectUser,
    makeSelectUserId,
    userIdSelector,
    makeSelectSubscriptionPlanId,
    makeSelectSubscription,
    makeSelectHasSubsciption,
    makeSelectNavState,
    selectPaymentSource,
    makeSelectEmail,
    makeSelectUserPicture,
    makeSelectIsGodUser,
    makeSelectLoginCount,
    makeSelectStripeCustomerId,
};
